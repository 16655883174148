.adminPanel {
    width: 100%;
    min-height: 100vh;
    background-color: hsl(219, 48%, 8%);
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .adminPanelContainer {
    width: 100%;
    max-width: 1200px;
    min-height: 100vh;
    background-color: hsl(240, 4%, 5%);
  }
  .adminPanelContainer > h4 {
    margin: 2vmax;
    letter-spacing: 15px;
    display: flex;
    transform: translateX(-100vw);
    animation: contactFormInputs 1s ease-in-out forwards;
    justify-content: center;
    color: #7c73f8;
  }
  .adminPanelContainer > h4 > p {
    transition: all 0.5s;
  }
  .adminPanelContainer > h4 > p:hover {
    transform: translateY(-10px);
  }
  
  .adminPanelContainer > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4vmax;
    padding-top: 0;
  }
  
  .adminPanelInputs {
    width: 50%;
    padding: 1vmax 2vmax;
    border: 1px solid rgb(7, 31, 128);
    border-radius: 50px;
    font: 400 1.2rem "Roboto", sans-serif;
    outline: none;
    margin: 2vmax;
    box-sizing: border-box;
    color: rgb(224, 228, 224);
    /* background-color: #0c0c10; */
  }
  .adminPanelInputs.input:not([type="button"]):not([type="submit"]):not([type="reset"]):hover, input:not([type="button"]):not([type="submit"]):not([type="reset"]):focus, textarea:hover, textarea:focus, select:hover, select:focus {
    border-color: #7c73f8;
    box-shadow: none;
}
  .adminPanelInputs::placeholder {
    color:#6e66d3;
}
  .adminPanelSkill {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .adminPanelSkill > div {
    display: flex;
    align-items: center;
    margin: 2vmax;
  }
  .adminPanelSkill > div > p {
    font: 400 1.5rem "Roboto", sans-serif;
    margin-right: 2vmax;
    color: rgba(134, 128, 249, 0.815);
  }
  
  .adminPanelFileUpload::-webkit-file-upload-button {
    width: 100%;
    border-radius: 30px;
    border: none;
    font: 300 1rem "Roboto", sans-serif;
    cursor: pointer;
    background-color: #7c73f8;
    padding: 1vmax;
    color: white;
  }
  
  .adminPanelAbout > fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid rgb(130, 73, 184);
    margin: 2vmax 0;
    padding-bottom: 3vmax;
  }
  
  .adminPanelAbout {
    width: 100%;
  }
  .adminPanelAbout > fieldset > legend {
    margin: 2vmax;
    font: 400 2rem "Roboto";
    color: #7c73f8;
  }
  
  .adminPanelContainer > form > a {
    background-color: #2a1587;
    padding: 2vmax;
    font: 400 2rem "Roboto", sans-serif;
    color: rgb(46, 180, 69);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 220px;
    margin: 2vmax;
    transition: all 0.5s;
  }
  .adminPanelContainer > form > a:hover {
    box-shadow: 0 0 10px rgba(107, 234, 117, 0.212);
    transform: scale(1.1);
  }
  .adminPanelContainer > form > button {
    width: 100%;
    padding: 1vmax;
    margin: 2vmax;
    background-color: hsl(221, 54%, 21%);
  }
  .adminPanelContainer > form > button:hover {
    background-color: hsl(222, 61%, 42%);
  }
  
.adminPanelYoutubeVideos {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.youtubeCard{
  color: #d3d2e1;
}
